<template>
  <Form
    send-text="OK"
    :loading="loading"
    :hide-reset="payment.id"
    @submit="onPaymentSubmit"
    @reset="onPaymentReset"
  >
    <v-row class="my-3" dense>
      <FormItem
        v-model="amount"
        name="Importo"
        type="number"
        placeholder="1920,00"
        dense
        prepend-inner-icon="mdi-currency-eur"
        :cols="12"
        :md="8"
        :xl="8"
        :lg="8"
        rules="required"
        step="0.01"
        :disabled="transfer"
      />
      <FormItem
        v-model="payment_date"
        type="datepicker"
        :cols="12"
        :xl="12"
        :lg="12"
        rules="required"
        maxToday
        name="Data Pagamento"
      />
      <FormItem
        v-model="annotation"
        type="text"
        name="Note"
        placeholder="Note"
        :cols="12"
        :xl="12"
        :lg="12"
      />
    </v-row>
    <v-row v-if="reconcile" class="my-3" dense>
      <FormItem
        v-model="ticket_id"
        type="text"
        name="Identificativo Debitore"
        placeholder="Identificativo Debitore"
        :rules="{ required: true }"
        :cols="12"
        :xl="12"
        :lg="12"
      />
    </v-row>
  </Form>
</template>

<script>
import moment from 'moment-timezone';
import FormItem from '@components/forms/FormItem.vue'
import Form from '@components/forms/Form.vue'
import {mapActions, mapMutations, mapState} from "vuex";
import {createHelpers} from "vuex-map-fields";
import { fixedCurrency } from '@utils/formdata';

const { mapFields } = createHelpers({
  getterType: 'payments/getCurrent',
  mutationType: 'payments/SET_CURRENT_FIELDS',
})

export default {
  name: "PaymentsForm",
  components: {Form, FormItem},
  props: {
    reconcile: {
      type: Boolean,
      default: false,
    },
    transfer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapFields(['ticket_id', 'annotation', 'amount', 'payment_type', 'payment_date']),
    ...mapState('payments', {
      loading: (state) => state.loading,
      payment: 'current',
    }),
  },
  mounted() {
    if (!this.payment.id) this.payment_date = moment();
    this.$root.$on('reset-payment', () => {
      this.checked = false;
      this.setTotalSelected = false;
    });
  },
  methods: {
    ...mapActions('payments', {
      createPayment: 'create',
      updatePayment: 'update',
    }),
    ...mapMutations('payments', {
      setCurrentPayment: 'SET_CURRENT',
      resetPayment: 'RESET_CURRENT',
    }),
    async onPaymentSubmit() {
      try {
        const amount = fixedCurrency(this.amount);
        if(this.payment.id) {
          if (this.reconcile) this.setCurrentPayment({ payment_type: 1 });
          if (this.transfer) {
            const res = await this.$dialog.confirm({
              text: "Proseguire con lo storno?\nL'azione non è reversibile!",
              title: 'Attenzione',
            });

            if (!res) return;
          }

          await this.updatePayment();
        } else {
          const paymentToPass = { amount, payment_type: 5, annotation: this.annotation, payment_date: this.payment_date };
          this.setCurrentPayment(paymentToPass);
          await this.createPayment();
        }
        this.resetPayment();
        if (!this.payment.id) this.payment_date = moment();
        this.$emit('submitted');
      } catch (err) {
        console.log('errore', err)
      }
    },
    onPaymentReset() {
      this.resetPayment();
      this.payment_date = moment();
    },
  },
}
</script>
