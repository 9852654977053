var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('PaymentsSectionSearch',{on:{"search":_vm.getItems,"reset":_vm.resetSearch}}),_c('v-card',{staticClass:"mt-5"},[_c('v-card-text',[_c('BaseTable',{ref:"table",attrs:{"title":"Pagamenti non riconciliabili / Eccedenze","module":"payments","headers":_vm.headers,"loading":_vm.loading,"elevation":0,"actions":_vm.actions,"sortby":"created_at","sort-desc":false},on:{"refresh":_vm.refreshTable},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount,'€'))+" ")]}},{key:"item.payment_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.payment_date,'DD/MM/YYYY', '-'))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.created_at,'DD/MM/YYYY', '-'))+" ")]}},{key:"item.payment_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.paymentTypeView[item.payment_type])+" ")]}},{key:"actions",fn:function(){return [(_vm.canUser('payments', 'create'))?_c('v-btn',{attrs:{"color":"green","dark":"","tile":"","depressed":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Inserisci pagamento ")]):_vm._e()]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"50%","fullscreen":_vm.$vuetify.breakpoint.mobile,"retain-focus":false},on:{"click:outside":_vm.refresh},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.modalTitle))]),_c('v-divider'),_c('v-card-text',[_c('PaymentsForm',{attrs:{"reconcile":_vm.reconcile,"transfer":_vm.transfer},on:{"submitted":_vm.onSubmitted}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }