<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="ticket_id" name="Identificativo" dense
        :xl="2"
      />

      <FormCustomers
        v-model="customer_id"
        :customer_id="customer_id"
        name="Debitore"
        dense
        clearable
        hint="Cerca per nominativo, CF, email o numero di telefono"
        persistent-hint
      />

      <FormItem
        v-model="payment_type"
        name="Stato"
        type="autocomplete"
        dense
        chips
        deletable-chips
        :values="paymentTypesArray"
        :xl="3"
      />

    </v-row>
    <v-row dense>

      <FormUsers
        v-model="managed_by"
        :platform_user_id="managed_by"
        label="Operatore"
        :use-username="true"
        dense
        clearable
        :additional-filters="{ role_id: [1,2,3,4] }"
      />
      <FormItem
        v-model="created_at"
        type="datepicker"
        name="Data Inserimento"
        hint="Ricerca per Data Inserimento"
        dense
        range
        :xl="2"
      />
      <FormItem
        v-model="payment_date"
        type="datepicker"
        name="Data Pagamento"
        hint="Ricerca per Data Pagamento"
        dense
        range
        :xl="2"
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapMutations, mapState } from 'vuex'

import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormCustomers from '@components/forms/FormCustomers.vue'
import FormUsers from '@components/forms/FormUsers.vue'
import { authComputed } from '@state/helpers.js'

const { mapFields } = createHelpers({
  getterType: 'payments/getFiltersFields',
  mutationType: 'payments/SET_FILTER_FIELDS',
})

export default {
  name: 'PaymentsSectionSearch',
  components: {
    FormCustomers,
    FormUsers,
    FormItem,
    BaseSectionSearch,
  },
  data() {
    return {
      paymentTypesArray: [],
    }
  },
  computed: {
    ...authComputed,
    ...mapState('payments', ['payment_type_map']),
    ...mapFields(['ticket_id', 'customer_id', 'payment_type', 'managed_by', 'created_at', 'payment_date']),
  },
  beforeMount() {
    this.paymentTypeMapping();
  },
  methods: {
    ...mapMutations('payments', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('reset')
    },
    paymentTypeMapping() {
      this.paymentTypesArray = this.payment_type_map.filter(type => [3,4,5,6].includes(type.value));
    },
  },
}
</script>
