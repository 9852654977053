<template>
  <v-container fluid>
    <PaymentsSectionSearch @search="getItems" @reset="resetSearch" />
    <v-card class="mt-5">
      <v-card-text>
        <BaseTable
          ref="table"
          title="Pagamenti non riconciliabili / Eccedenze"
          module="payments"
          :headers="headers"
          :loading="loading"
          :elevation="0"
          :actions="actions"
          sortby="created_at"
          :sort-desc="false"
          @refresh="refreshTable"
        >
          <template v-slot:item.amount="{ item }">
            {{ item.amount | currency('€') }}
          </template>
          <template v-slot:item.payment_date="{ item }">
            {{ item.payment_date | momentOr('DD/MM/YYYY', '-') }}
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | momentOr('DD/MM/YYYY', '-') }}
          </template>
          <template v-slot:item.payment_type="{ item }">
            {{ paymentTypeView[item.payment_type] }}
          </template>
          <template v-slot:actions>
            <v-btn
              v-if="canUser('payments', 'create')"
              color="green"
              dark
              tile
              depressed
              @click="dialog = true"
            >
              Inserisci pagamento
            </v-btn>
          </template>
        </BaseTable>
        <v-dialog
          v-model="dialog"
          max-width="50%"
          :fullscreen="$vuetify.breakpoint.mobile"
          :retain-focus="false"
          @click:outside="refresh"
        >
          <v-card>
            <v-card-title>{{ modalTitle }}</v-card-title>
            <v-divider />
            <v-card-text>
              <PaymentsForm :reconcile="reconcile" :transfer="transfer" @submitted="onSubmitted" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue';
import PaymentsSectionSearch from '@components/payments/PaymentsSectionSearch.vue';
import PaymentsForm from '@components/payments/PaymentsForm.vue';
import { mapActions, mapGetters, mapMutations } from "vuex";
import {authComputed} from "@state/helpers";

export default {
  name: 'PaymentsSearch',
  components: {
    BaseTable,
    PaymentsSectionSearch,
    PaymentsForm,
  },
  page: {
    title: 'Pagamenti non riconciliabili o eccedenze',
  },
  data() {
    return {
      headers: [
        {
          text: 'ID',
          value: 'ticket_id',
        },
        {
          text: 'Debitore',
          value: 'customer.common_name',
        },
        {
          text: 'Data Inserimento',
          value: 'created_at',
        },
        {
          text: 'Stato',
          value: 'payment_type',
        },
        {
          text: 'Importo',
          value: 'amount',
        },
        {
          text: 'Data pagamento',
          value: 'payment_date',
        },
        {
          text: 'Note',
          value: 'annotation',
        },
        {
          text: 'Operatore',
          value: 'managed_by',
        },
      ],
      dialog: false,
      reconcile: false,
      transfer: false,
      modalTitle: 'Inserisci Pagamento',
      actions: [
        {
          key: 'edit',
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          onItemCondition: (item) => {
            return this.canUser('payments', 'update')
              && item.praticx_exported !== 'exported'
              && !item.reversed && [3, 5].includes(item.payment_type);
          },
          handler: this.editPayment,
        },
        {
          key: 'remove',
          label: 'Elimina',
          icon: 'mdi-delete',
          color: 'red',
          onItemCondition: (item) => {
            return this.canUser('payments', 'delete')
              && item.praticx_exported !== 'exported'
              && ((!item.reversed && [3, 5].includes(item.payment_type)) || [4, 6].includes(item.payment_type));
          },
          handler: this.removePayment,
        },
        {
          key: 'register_storno',
          label: 'Registra storno',
          color: 'primary',
          onItemCondition: (item) => {
            return this.canUser('payments', 'update')
              && !item.reversed && [3, 5].includes(item.payment_type);
          },
          handler: this.paymentTransfer,
        },
        {
          key: 'reconcile',
          label: 'Riconcilia',
          color: 'primary',
          onItemCondition: (item) => {
            return this.canUser('payments', 'update')
              && item.praticx_exported !== 'exported'
              && !item.reversed && item.payment_type === 5;
          },
          handler: this.paymentReconcile,
        },
      ],
      loading: false,
    };
  },
  computed: {
    ...authComputed,
    ...mapGetters('payments', ['paymentTypeView']),
  },
  beforeMount() {
    this.reset();
    this.setFilters();
    this.setScopes(['filterByCustomer', 'ticket']);
  },
  methods: {
    ...mapActions('payments', {
      getItems: 'getItems',
      removePaymentItem: 'removeItem',
      updatePaymentItem: 'update',
    }),
    ...mapMutations('payments', {
      setPaymentFilters: 'SET_FILTERS',
      setScopes: 'SET_SCOPES',
      setCurrentPayment: 'SET_CURRENT',
      resetPayment: 'RESET_CURRENT',
      resetFilters: 'RESET_FILTER_FIELDS',
      resetScope: 'RESET_SCOPES',
      setCtx: 'SET_CTX',
    }),
    setFilters() {
      this.setPaymentFilters({ payment_type: [3, 4, 5, 6] });
    },
    refresh() {
      this.resetPayment();
      this.modalTitle = 'Inserisci Pagamento';
      this.reconcile = false;
      this.$refs.table.refresh();
      this.$root.$emit('reset-payment');
    },
    async onSubmitted() {
      this.dialog = false;
      this.reconcile = false;
      this.transfer = false;
      this.modalTitle = 'Inserisci Pagamento';
      this.$refs.table.refresh();
    },
    async editPayment(item) {
      const paymentToPass = { id: item.id, annotation: item.annotation, ticket_id: item.ticket_id, payment_type: item.payment_type, amount: item.amount, payment_date: item.payment_date, invoiced: item.invoiced };
      this.setCurrentPayment(paymentToPass);
      this.modalTitle = 'Modifica Pagamento';
      this.dialog = true;
    },
    async paymentReconcile(item) {
      const paymentToPass = { id: item.id, payment_type: item.payment_type, amount: item.amount, payment_date: item.payment_date, invoiced: item.invoiced };
      this.setCurrentPayment(paymentToPass);
      this.reconcile = true;
      this.modalTitle = 'Riconcilia';
      this.dialog = true;
    },
    async paymentTransfer(item) {
      const paymentType = item.payment_type === 3 ? 4 : 6;
      const paymentToPass = { id: item.id, ticket_id: item.ticket_id, payment_type: paymentType, amount: item.amount, payment_date: item.payment_date };
      this.setCurrentPayment(paymentToPass);
      this.transfer = true;
      this.modalTitle = 'Storna';
      this.dialog = true;
    },
    async removePayment(item) {
      const res = await this.$dialog.confirm({
        text: "Proseguire con l'eliminazione?\nL'azione non è reversibile!",
        title: 'Attenzione',
      });

      if (!res) return;

      await this.removePaymentItem(item.id);
      await this.$refs.table.refresh();
    },
    resetSearch() {
      this.setFilters();
      this.setScopes(['filterByCustomer']);
      this.getItems();
    },
    reset() {
      this.resetFilters();
      this.resetScope()
    },
    refreshTable(ctx) {
      this.setCtx(ctx);
    },
  },
}
</script>
